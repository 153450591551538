


























































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ContrattoSuOspiteReturned, Portineria } from "operatn-api-client";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { createXlsxBlob, downloadBlob } from "@/utils";

import RicercaOspitiMixin, { ControlliType } from "@/mixins/handlers/RicercaOspitiMixin";
import PortineriaHandlerMixin from "@/mixins/handlers/PortineriaHandlerMixin";

import OperatnBaseTable, { Column, GroupHeaders } from "@/components/gears/bases/OperatnBaseTable.vue";

if (pdfMake.vfs == undefined) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
}

@Component({
  components: {
    OperatnBaseTable,
  },
})
export default class Controlli extends Mixins(RicercaOspitiMixin, PortineriaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;

  /* DATA */

  currentDates: Date[] = [];
  contrattiLoading = false;
  contratti: ContrattoSuOspiteReturned[] = [];
  selectedButton = "";

  portinerie: Portineria[] = [];
  portineriaFilter: number | null = null;
  tableSearch: string | null = null;

  datesValue: Date[] = [];

  exportMappingExcel: [string, (x: any) => string][] = [
    ["Nome", (x) => x.ospite.persona.nome],
    ["Cognome", (x) => x.ospite.persona.cognome],
    ["Sesso", (x) => x.ospite.persona.sesso],
    ["Cittadinanza", (x) => x.ospite.cittadinanza],
    ["Telefono principale", (x) => x.ospite.telefonoPrincipale],
    ["Codice fiscale", (x) => x.ospite.persona.codiceFiscale],
    ["Email", (x) => x.ospite.email],
    ["Fabbricato", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.nome],
    ["Stanza", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza],
    ["Posto letto", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.postoLetto],
    ["Inizio contratto", (x) => this.parsePdfAndXlsDate(x.contratto.dataInizio)],
    ["Fine contratto", (x) => this.parsePdfAndXlsDate(x.contratto.dataFine)],
    ["Chiusura anticipata", (x) => this.parsePdfAndXlsDate(x.contratto.dataChiusuraAnticipata)],
    ["Check in", (x) => this.parsePdfAndXlsDateTime(x.dataCheckin)],
    ["Check out", (x) => this.parsePdfAndXlsDateTime(x.dataCheckout)],
    ["Lasciapassare", (x) => this.parsePdfAndXlsDate(x.dataConsegnaLasciapassare)],
    ["Kit", (x) => this.parsePdfAndXlsDate(x.dataConsegnaKit)],
  ];

  exportMappingPdf: [string, (x: any) => string][] = [
    ["Nome", (x) => x.ospite.persona.nome],
    ["Cognome", (x) => x.ospite.persona.cognome],
    ["Sesso", (x) => this.parsePdfSesso(x.ospite.persona.sesso)],
    ["Fabbricato", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.nome],
    ["Stanza", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza],
    ["Posto .etto", (x) => x.contrattiSuOspiteSuPostoLetto[0].postoLetto.postoLetto],
    ["Data inizio", (x) => this.parsePdfAndXlsDate(x.contratto.dataInizio)],
    ["Data fine", (x) => this.parsePdfAndXlsDate(x.contratto.dataFine)],
    ["Chiusura anticipata", (x) => this.parsePdfAndXlsDate(x.contratto.dataChiusuraAnticipata)],
  ];

  /* GETTERS AND SETTERS */

  get columns(): Column<ContrattoSuOspiteReturned>[] {
    return [
      {
        text: "Nome",
        value: "ospite.persona.nome",
        sortable: true,
      },
      {
        text: "Cognome",
        value: "ospite.persona.cognome",
        sortable: true,
      },
      {
        text: "Sesso",
        value: "ospite.persona.sesso",
        groupable: true,
        sortable: true,
      },
      {
        text: "Cittadinanza",
        value: "ospite.cittadinanza",
        sortable: true,
      },
      {
        text: "Telefono principale",
        value: "ospite.telefonoPrincipale",
        sortable: true,
      },
      {
        text: "Codice fiscale",
        value: "ospite.persona.codiceFiscale",
        sortable: true,
      },
      {
        text: "Email",
        value: "ospite.email",
        sortable: true,
      },
      {
        text: "Fabbricato",
        value: "contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.codice",
        groupable: true,
        sortable: true,
      },
      {
        text: "Stanza",
        value: "contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza",
        groupable: true,
        sortable: true,
      },
      {
        text: "Posto letto",
        value: "contrattiSuOspiteSuPostoLetto[0].postoLetto.postoLetto",
        sortable: true,
      },
      {
        text: "Inizio contratto",
        value: "contratto.dataInizio",
        itemTextHandler: (date: string) => this.dateParser(date),
      },
      {
        text: "Fine contratto",
        value: "contratto.dataFine",
        itemTextHandler: (date: string) => this.dateParser(date),
      },
      {
        text: "Chiusura anticipata",
        value: "contratto.dataChiusuraAnticipata",
        itemTextHandler: (date: string) => this.dateParser(date),
      },
      {
        text: "Check in",
        value: "dataCheckin",
        itemTextHandler: (date: string) => this.dateParser(date),
      },
      {
        text: "Check out",
        value: "dataCheckout",
        itemTextHandler: (date: string) => this.dateParser(date),
      },
      {
        text: "C. lasciapassare",
        value: "dataConsegnaLasciapassare",
        itemTextHandler: (date: string) => this.dateParser(date, "No"),
      },
      {
        text: "C. Kit",
        value: "dataConsegnaKit",
        itemTextHandler: (date: string) => this.dateParser(date, "No"),
      },
    ];
  }

  get dates(): Date[] {
    return this.datesValue;
  }
  set dates(value: Date[]) {
    this.datesValue = value;
    if (value.length === 2) {
      if (this.datesValue[0] > this.datesValue[1]) {
        const t = this.datesValue[0];
        this.datesValue[0] = this.datesValue[1];
        this.datesValue[1] = t;
      }
      this.selectedButton = "";
      this.contratti = [];
    }
  }

  get filteredContratti(): ContrattoSuOspiteReturned[] {
    return this.contratti
      .filter(
        (contratto) =>
          this.portineriaFilter === null || (contratto as any).contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.fabbricato?.idPortineria === this.portineriaFilter
      )
      .sort((x: any, y: any) => {
        const orderFabbricato = x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.codice.localeCompare(
          y.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.codice
        );
        const orderStanza = x.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza.localeCompare(
          y.contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza
        );
        const orderPostoLetto = x.contrattiSuOspiteSuPostoLetto[0].postoLetto.postoLetto.localeCompare(y.contrattiSuOspiteSuPostoLetto[0].postoLetto.postoLetto);
        return orderFabbricato === 0 ? (orderStanza === 0 ? orderPostoLetto : orderStanza) : orderFabbricato;
      });
  }

  get groupHeaders(): GroupHeaders {
    return {
      keyHandler: (key) => {
        switch (key) {
          case "contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.codice":
            return "FABBRICATO";
          case "contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza":
            return "STANZA";
          default:
            return key;
        }
      },
    };
  }

  /* METHODS */

  getCheckIn() {
    this.loadContratti("checkin");
  }
  getCheckOut() {
    this.loadContratti("checkout");
  }
  getCheckStarts() {
    this.loadContratti("starts");
  }
  getCheckEnds() {
    this.loadContratti("ends");
  }

  async loadContratti(type: ControlliType) {
    if (this.dates.length >= 2 && this.dates[0] && this.dates[1]) {
      const from = new Date(this.dates[0]);
      const to = new Date(this.dates[1]);
      if (from instanceof Date && !isNaN(from.valueOf()) && to instanceof Date && !isNaN(to.valueOf())) {
        this.contrattiLoading = true;
        this.currentDates = [new Date(this.dates[0]), new Date(this.dates[1])];
        this.contratti = await this.getContrattiControlli(type, from, to);
        this.contrattiLoading = false;
      }
    }
  }

  dateParser(date: string, defaultValue = "") {
    return date ? new Date(date).toLocaleDateString() : defaultValue;
  }

  getSubset(obj: ContrattoSuOspiteReturned) {
    return this.exportMappingExcel.reduce((acc, curr) => {
      acc[curr[0]] = curr[1](obj);
      return acc;
    }, {});
  }

  async exportExcel(): Promise<void> {
    const handledContratti = this.filteredContratti.map((x) => this.getSubset(x));
    const headers = Object.keys(handledContratti[0]).reduce((result, key) => ({ ...result, [key]: key }), {});
    const blob = await createXlsxBlob("Esportazione controlli", headers, handledContratti);
    downloadBlob(blob, `${this.dates[0].toString()}~${this.dates[1].toString()}.xlsx`);
  }

  parsePdfAndXlsDate(date: string | null): string {
    return date ? new Date(date).toLocaleDateString() : "-";
  }

  parsePdfAndXlsDateTime(date: string | null): string {
    return date ? new Date(date).toLocaleString() : "-";
  }

  parsePdfSesso(s: string): string {
    if (s.toLowerCase() === "femmina") return "F";
    if (s.toLowerCase() === "maschio") return "M";
    return "-";
  }

  printPdf() {
    const rows = [this.exportMappingPdf.map((x) => x[0])];

    this.filteredContratti.forEach((i) => {
      rows.push(
        this.exportMappingPdf.map((x) => {
          const val = x[1](i);
          return val ? val.trim() : "";
        })
      );
    });

    pdfMake
      .createPdf({
        info: {
          author: "Software OperaGest fornito da https://github.com/euberdeveloper",
        },
        pageOrientation: "landscape",
        defaultStyle: {
          fontSize: 11,
          bold: true,
        },
        content: [
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              body: rows,
            },
          },
        ],
      })
      .download(`${this.datesValue[0].toString()}~${this.datesValue[1].toString()}.pdf`);
  }

  /* LIFE CYCLE */

  async created() {
    this.portinerie = await this.getPortinerie();
  }
}
