
import { Component, Vue } from "vue-property-decorator";
import { RicercaOspitiResult, PersonaReturned, CheckOutReturnedObjects, ContrattoSuOspiteReturned } from "operatn-api-client";

import { ActionTypes } from "@/store";

export type ControlliType = 'checkin' | 'checkout' | 'starts' | 'ends';

@Component
export default class RicercaOspitiMixin extends Vue {

  async searchOspiti(query: string): Promise<RicercaOspitiResult[]> {
    try {
      return await this.$api.ricercaOspiti.search(query);
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile trovare gli ospiti` });
      }
      throw error;
    }
  }

  async getOspite(id: number): Promise<PersonaReturned | null> {
    try {
      return await this.$api.ricercaOspiti.getId(id);
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile trovare la persona` });
      }
      throw error;
    }
  }

  async checkIn(contractId: number, ospiteId: number): Promise<Date | null> {
    try {
      return (await this.$api.ricercaOspiti.checkIn(contractId, ospiteId)).date;
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile effettuare il checkin` });
      }
      throw error;
    }
  }

  async checkInRemove(contractId: number, ospiteId: number): Promise<Date | null> {
    try {
      return (await this.$api.ricercaOspiti.checkInRemove(contractId, ospiteId)).date;
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile annullare il checkin` });
      }
      throw error;
    }
  }

  async checkOut(contractId: number, ospiteId: number, returnedObjects: CheckOutReturnedObjects = {}): Promise<Date | null> {
    try {
      return (await this.$api.ricercaOspiti.checkOut(contractId, ospiteId, returnedObjects)).date;
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile annullare il checkin` });
      }
      throw error;
    }
  }

  async checkOutRemoved(contractId: number, ospiteId: number): Promise<Date | null> {
    try {
      return (await this.$api.ricercaOspiti.checkOutRemove(contractId, ospiteId)).date;
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile annullare il checkin` });
      }
      throw error;
    }
  }

  async getContrattiControlli(type: ControlliType, dateFrom: Date, dateTo: Date): Promise<ContrattoSuOspiteReturned[]> {
    try {
      if (type === 'starts')
        return await this.$api.ricercaOspiti.controlliStarts(dateFrom, dateTo);
      if (type === 'ends')
        return await this.$api.ricercaOspiti.controlliEnds(dateFrom, dateTo);
      if (type === 'checkin')
        return await this.$api.ricercaOspiti.controlliCheckIn(dateFrom, dateTo);
      if (type === 'checkout')
        return await this.$api.ricercaOspiti.controlliCheckOut(dateFrom, dateTo);
      return [];
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare i contratti` });
      }
      throw error;
    }
  }

  async getPdfQuestura(contractId: number, ospiteId: number): Promise<Blob> {
    try {
      return (await this.$api.ricercaOspiti.getPdfQuestura(contractId, ospiteId));
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile scaricare il file` });
      }
      throw error;
    }
  }

}