

































































import { Component, Mixins, Prop } from "vue-property-decorator";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnBaseTable, { Actions, Column } from "@/components/gears/bases/OperatnBaseTable.vue";
import { Fabbricato, Stanza, ContrattoSuOspiteWithPostoLetto } from "operatn-api-client";
import FabbricatoHandlerMixin from "@/mixins/handlers/FabbricatoHandlerMixin";
import StanzaHandlerMixin from "@/mixins/handlers/StanzaHandlerMixin";

export type ContrattiAndPostoLetto = ContrattoSuOspiteWithPostoLetto & { postoLetto: string };

@Component({
  components: {
    OperatnDateInput,
    OperatnBaseTable,
  },
})
export default class RicercaStanze extends Mixins<
    FabbricatoHandlerMixin, StanzaHandlerMixin
  >(
    FabbricatoHandlerMixin, StanzaHandlerMixin
  ) {
  /* DATA */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;
  
  fabbricati: Fabbricato[] = [];
  fabbricatiLoading = true;
  selectedFabbricato: Fabbricato | null = null;

  // piani: string[] = [];
  // pianiLoading = false;
  // selectedPiano: string | null = null;

  stanze: Stanza[] = [];
  stanzeLoading = false;
  selectedStanza: Stanza | null = null;

  contratti: ContrattiAndPostoLetto[] = [];
  loadingContratti = false;

  async mounted() {
    this.fabbricati = await this.getFabbricati();
    this.fabbricatiLoading = false;
  }

  async onSetFabbricato(fabbricato: Fabbricato | null) {
    this.selectedFabbricato = fabbricato ?? null;
    if (this.selectedFabbricato) {
      this.stanzeLoading = true;
      this.stanze = await this.getStanzeByFabbricatoId(this.selectedFabbricato.id);
      this.stanzeLoading = false;
    } else {
      this.selectedStanza = null;
      this.stanze = []
    }
  }

  // async onSetPiano(piano: string | null) {
  //   this.selectedPiano = piano ?? null;
  //   if (this.selectedFabbricato && this.selectedPiano) {
  //     this.stanzeLoading = true;
  //     this.stanze = await this.getStanzeByPiano(this.selectedFabbricato.id, this.selectedPiano);
  //     this.stanzeLoading = false;
  //   } else {
  //     this.selectedStanza = null;
  //     this.stanze = []
  //   }
  // }

  groupBy<T>(xs: T[], key: (x: T) => string): { [id: string]: T[] }  {
    return xs.reduce(function(rv, x) {
      (rv[key(x)] = rv[key(x)] || []).push(x);
      return rv;
    }, {});
  }

  async onSetStanza(stanza: Stanza | null) {
    this.selectedStanza = stanza;
    this.contratti = []
    if (this.selectedFabbricato && this.selectedStanza) {
      this.loadingContratti = true;

      this.contratti = (await this.getContrattiByStanzaId(this.selectedFabbricato.id, this.selectedStanza.id)).map(x => ({
        ...x,
        postoLetto: x.contrattiSuOspiteSuPostoLetto.map(pl => pl.postoLetto.postoLetto).join(', ')
      }));


    }
    this.loadingContratti = false;
  }


    get columns(): Column<ContrattiAndPostoLetto>[] {
    return [
      {
        text: "Nome",
        value: "ospite.persona.nome"
      },
      {
        text: "Cognome",
        value: "ospite.persona.cognome"
      },
      {
        text: "Sesso",
        value: "ospite.persona.sesso"
      },
      {
        text: "Letto",
        value: "postoLetto"
      },
      {
        text: "Check In",
        value: "dataCheckin",
        itemTextHandler: (date: string) => date ? new Date(date).toLocaleDateString() : '',
      },
      {
        text: "Check Out",
        value: "dataCheckout",
        itemTextHandler: (date: string) => date ? new Date(date).toLocaleDateString() : '',
      },
      {
        text: "Data Inizio",
        value: "contratto.dataInizio",
        itemTextHandler: (date: string) => date ? new Date(date).toLocaleDateString() : '',
      },
      {
        text: "Data Fine",
        value: "contratto.dataFine",
        itemTextHandler: (date: string) => date ? new Date(date).toLocaleDateString() : '',
      },
      {
        text: "Chiusura anticipata",
        value: "contratto.dataChiusuraAnticipata",
        itemTextHandler: (date: string) => date ? new Date(date).toLocaleDateString() : '',
      }
    ];
  }

  
  get actions(): Actions<ContrattiAndPostoLetto> {
    return {
      onView: (item) => `ospiti/${item.ospite.id}`
    };
  }


}
