




















































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Tabellone, TabelloneQueryParams } from "operatn-api-client";

import TabelloneHandlerMixin from "@/mixins/handlers/TabelloneHandlerMixin";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import { downloadBlob } from "@/utils";
import OperatnBaseTable, { Column } from "@/components/gears/bases/OperatnBaseTable.vue";

@Component({
  components: {
    OperatnDateInput,
    OperatnBaseTable,
  },
})
export default class TabelloneEsporta extends Mixins(TabelloneHandlerMixin) {
  /* DATA */

  private formValid = false;
  private formValue = this.getEmptyBody();
  private loading = {
    xlsx: false,
    tsv: false,
    visulize: false,
  };

  private tabellone: Tabellone[] = [];
  private columns: Column<Tabellone>[] = [
    {
      text: "Fabbricato",
      value: "fabbricatoCodice",
      groupable: true,
      editable: false,
    },
    {
      text: "Unità immobiliare",
      value: "stanzaUnitaImmobiliare",
      groupable: true,
      editable: false,
    },
    {
      text: "Numero stanza",
      value: "stanzaNumeroStanza",
      groupable: true,
      editable: false,
    },
    {
      text: "Tipo stanza",
      value: "tipoStanzaTipoStanza",
      groupable: true,
      editable: false,
    },
    {
      text: "Piano",
      value: "stanzaPiano",
      groupable: true,
      editable: false,
    },
    {
      text: "Posto letto",
      value: "postoLettoPostoLetto",
      groupable: false,
      editable: false,
    },
    {
      text: "Manutenzione",
      value: "manutenzioneDataCreazione",
      groupable: false,
      editable: false,
      sort: this.compareStringDates
    },
    {
      text: "Nome",
      value: "personaNome",
      groupable: false,
      editable: false,
    },
    {
      text: "Cognome",
      value: "personaCognome",
      groupable: false,
      editable: false,
    },
    {
      text: "Email",
      value: "ospiteEmail",
      groupable: false,
      editable: false,
    },
    {
      text: "Telefono principale",
      value: "ospiteTelefonoPrincipale",
      groupable: false,
      editable: false,
    },
    {
      text: "Telefono secondario",
      value: "ospiteTelefonoSecondario",
      groupable: false,
      editable: false,
    },
    {
      text: "Cittadinanza",
      value: "ospiteCittadinanza",
      groupable: true,
      editable: false,
    },
    {
      text: "Dipartimento",
      value: "dipartimentoUnitnCodice",
      groupable: true,
      editable: false,
    },
    {
      text: "Inizio contratto",
      value: "contrattoDataInizio",
      groupable: false,
      editable: false,
      sort: this.compareStringDates
    },
    {
      text: "Fine contratto",
      value: "contrattoDataFine",
      groupable: false,
      editable: false,
      sort: this.compareStringDates
    },
    {
      text: "Tipo contratto",
      value: "tipoContrattoSigla",
      groupable: true,
      editable: false,
    },
    {
      text: "Tipo ospite",
      value: "tipoOspiteSigla",
      groupable: true,
      editable: false,
    },
  ];

  /* GETTERS */

  get queryParams(): TabelloneQueryParams {
    return { startDate: this.formValue.dataInizio as Date, endDate: this.formValue.dataFine as Date };
  }

  get isDisabled(): boolean {
    return !this.formValid || Object.keys(this.loading).some((k) => this.loading[k]);
  }

  /* WATCH */

  @Watch("formValue", { deep: true })
  watchFormValue() {
    this.tabellone = [];
  }

  /* METHODS */

  private getEmptyBody() {
    return {
      dataInizio: null as Date | null,
      dataFine: null as Date | null,
    };
  }

  private getFileName(extension: string) {
    const timestamp = new Date().toISOString().slice(0, 10).replace(/-/g, "_");
    return `tabellone__${timestamp}.${extension}`;
  }

  private compareStringDates(x: string, y: string) {
        if (!x) {
          return -1;
        }
        else if (!y) {
          return 1;
        }
        else {
          const [xDay, xMonth, xYear] = x.split("/").map(v => +v);
          const [yDay, yMonth, yYear] = y.split("/").map(v => +v);
          const xDate = new Date(xYear, xMonth - 1, xDay);
          const yDate = new Date(yYear, yMonth - 1, yDay);
          return +xDate - +yDate;
        }
      }

  async fetchTsv(): Promise<void> {
    if (!this.isDisabled) {
      try {
        this.loading.tsv = true;
        const blob = await this.getTabelloneTsv(this.queryParams);
        downloadBlob(blob, this.getFileName("tsv"));
      } finally {
        this.loading.tsv = false;
      }
    }
  }
  async fetchXlsx(): Promise<void> {
    if (!this.isDisabled) {
      try {
        this.loading.xlsx = true;
        const blob = await this.getTabelloneXlsx(this.queryParams);
        downloadBlob(blob, this.getFileName("xlsx"));
      } finally {
        this.loading.xlsx = false;
      }
    }
  }
  async visualize(): Promise<void> {
    if (!this.isDisabled) {
      try {
        this.loading.visulize = true;
        this.tabellone = await this.getTabellone(this.queryParams);
      } finally {
        this.loading.visulize = false;
      }
    }
  }
}
